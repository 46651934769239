import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./sba-approved-lenders.css"
import { Link } from "gatsby"

const BusinessTechnicalAssistance = () => {
  return (
    <Layout>
      <SEO title="Additional Funding Resources" />
        <div className="container primary-bg">
          <h1 class="small-padding">Available Funding Resources</h1>
          <p className="small-padding">
            We recognize the importance of accessing various sources for funding
            as you develop a recovery and sustainable business strategy. Take a
            look at this broad range of funding options from the federal, state
            and local levels.
          </p>
          <hr />
          <Link className="full-cta" to="/additional-funding-state-and-federal">
            State & Federal Programs
          </Link>
          <Link className="full-cta" to="/additional-funding-foundations">
            Foundations
          </Link>
          <Link className="full-cta" to="/additional-funding-grants">
            Grants
          </Link>
          <Link className="full-cta" to="/additional-funding-non-profit">
            Non-Profits
          </Link>
          <Link className="full-cta" to="/additional-funding-partners">
            Partners Organizations
          </Link>
          <hr />
    
      </div>
    </Layout>
  )
}

export default BusinessTechnicalAssistance
